import RequireAuth from './RequireAuth';
import RequireNoAuth from './RequireNoAuth';
import { Home } from '../pages/home/Home';
import { Login } from '../pages/authentication/login/Login';
import { RegisterAccount } from '../pages/authentication/register/RegisterAccount';
import { RegisterUsername } from '../pages/authentication/register/RegisterUsername';
import { RegisterCountry } from '../pages/authentication/register/RegisterCountry';
import { RegisterProfile } from '../pages/authentication/register/RegisterProfile';
import { RegisterStripe } from '../pages/authentication/register/RegisterStripe';
import { ForgotPassword } from '../pages/authentication/forgot-password/ForgotPassword';
import { AccountSettings } from '../pages/account-settings/AccountSettings';
import { CheckoutHOC } from '../pages/checkout/CheckoutHOC';
import { PaymentCompleteHOC } from '../pages/payment-complete/PaymentCompleteHOC';
import { NotFound } from '../pages/not-found/NotFound';
import { createBrowserRouter } from 'react-router-dom';
import { Dashboard } from '../pages/dashboard/Dashboard';
import { ServerError } from '../pages/server-error/ServerError';
import { PrivacyPolicy } from '../pages/privacy-policy/PrivacyPolicy';
import { TermsOfService } from '../pages/terms-of-service/TermsOfService';
import { HowItWorks } from '../pages/how-it-works/HowItWorks';
import { ContactUs } from '../pages/contact-us/ContactUs';
import { FrequentlyAskedQuestions } from '../pages/frequently-asked-questions/FrequentlyAskedQuestions';
import { VerifyEmail } from '../pages/verify-email/VerifyEmail';
import { Root } from './Root';
import { ResetForgotPassword } from '../pages/reset-forgot-password/ResetForgotPassword';
import { ProfileHOC } from '../pages/profile/ProfileHOC';

const router = createBrowserRouter([
  {
    element: <Root />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: 'login',
        element: (
          <RequireNoAuth>
            <Login />
          </RequireNoAuth>
        ),
      },
      {
        path: 'register',
        element: (
          <RequireNoAuth>
            <RegisterUsername />
          </RequireNoAuth>
        ),
      },
      {
        path: 'register/country',
        element: (
          <RequireNoAuth>
            <RegisterCountry />
          </RequireNoAuth>
        ),
      },
      {
        path: 'register/account',
        element: (
          <RequireNoAuth>
            <RegisterAccount />
          </RequireNoAuth>
        ),
      },
      {
        path: 'register/profile',
        element: <RegisterProfile />,
      },
      {
        path: 'register/stripe',
        element: <RegisterStripe />,
      },
      {
        path: 'forgot-password',
        element: (
          <RequireNoAuth>
            <ForgotPassword />
          </RequireNoAuth>
        ),
      },
      {
        path: 'account-settings',
        element: (
          <RequireAuth>
            <AccountSettings />
          </RequireAuth>
        ),
      },
      {
        path: 'dashboard',
        element: (
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        ),
      },
      { path: 'checkout', element: <CheckoutHOC /> },
      { path: 'complete', element: <PaymentCompleteHOC /> },
      { path: 'not-found', element: <NotFound /> },
      { path: 'server-error', element: <ServerError /> },
      { path: 'terms-of-service', element: <TermsOfService /> },
      { path: 'privacy-policy', element: <PrivacyPolicy /> },
      { path: 'how-it-works', element: <HowItWorks /> },
      { path: 'contact-us', element: <ContactUs /> },
      { path: 'faq/*', element: <FrequentlyAskedQuestions /> },
      { path: 'confirm-email/:token', element: <VerifyEmail /> },
      {
        path: 'reset-password/:userId/:token',
        element: <ResetForgotPassword />,
      },
      { path: ':username', element: <ProfileHOC /> },
    ],
  },
]);

export default router;
