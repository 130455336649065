import React, { useEffect, useState } from 'react';
import { FansFundMeRegisterUsernameScreen } from '@fans-fund-me/storybook';
import { useNavigate } from 'react-router-dom';
import validate from '../../../utilities/validation';
import axios from 'axios';
import useAuth from '../../../contexts/UseAuth';

export const RegisterUsername = () => {
  const API_ENDPOINT: string = process.env.REACT_APP_API_ENDPOINT_PROFILE ?? '';

  const navigate = useNavigate();
  const auth = useAuth();

  const [username, setUsername] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    setError('');
    localStorage.removeItem('ffm_register_username');
  }, [username]);

  const handleRegisterUsername = () => {
    setIsLoading(true);

    if (validate('username', username, setError)) {
      axios
        .get(`${API_ENDPOINT}/username/${username}`)
        .then(() => {
          localStorage.setItem('ffm_register_username', username);
          navigate('/register/country');
        })
        .catch((apiError) => {
          if (
            apiError.response.status === 409 ||
            apiError.response.status === 400
          ) {
            setError(
              apiError.response.data.fields?.find(
                (field: { fieldName: string; message: string }) =>
                  field.fieldName === 'username'
              )?.message
            );
          } else {
            setError(
              'There was an error processing your request. Please try again.'
            );
          }
        });
    }

    setIsLoading(false);
  };

  return (
    <FansFundMeRegisterUsernameScreen
      username={username}
      setUsername={setUsername}
      onClickRegisterUsername={handleRegisterUsername}
      error={error}
      isLoading={isLoading}
      cookieBannerIsVisible={auth.cookies === null}
      onClickAcceptCookies={auth.acceptCookies}
      onClickAcceptNecessaryCookies={auth.acceptNecessaryCookies}
    />
  );
};
