import React, { useState } from 'react';
import { FansFundMeResetForgotPassword } from '@fans-fund-me/storybook';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '../../contexts/UseAuth';
import axios from 'axios';
import validate from '../../utilities/validation';

export const ResetForgotPassword = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  const { token } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');

  const onSubmit = () => {
    setIsLoading(true);
    const AUTH_API_ENDPOINT: string =
      process.env.REACT_APP_API_ENDPOINT_AUTHENTICATION ?? '';

    if (
      validate('password', password, setPasswordError) &&
      validate(
        'confirm password',
        confirmPassword,
        setConfirmPasswordError,
        'match',
        'password',
        password
      )
    ) {
      axios
        .put(`${AUTH_API_ENDPOINT}/reset-password/${token}`, {
          newPassword: password,
        })
        .then(() => {
          setIsLoading(false);
          navigate('/login');
        })
        .catch((err) => {
          setIsLoading(false);
          setPasswordError(
            err.response.data.fields?.find(
              (field: { fieldName: string; message: string }) =>
                field.fieldName === 'password'
            )?.message
          );
        });
    }
  };

  return (
    <FansFundMeResetForgotPassword
      authUser={auth.authUser}
      password={password}
      setPassword={setPassword}
      confirmPassword={confirmPassword}
      setConfirmPassword={setConfirmPassword}
      onClickSubmit={onSubmit}
      passwordError={passwordError}
      confirmPasswordError={confirmPasswordError}
      isLoading={isLoading}
      cookieBannerIsVisible={auth.cookies === null}
      onClickAcceptCookies={auth.acceptCookies}
      onClickAcceptNecessaryCookies={auth.acceptNecessaryCookies}
    />
  );
};
