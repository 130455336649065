import React, { useState } from 'react';
import { FansFundMeContactUsScreen } from '@fans-fund-me/storybook';
import useAuth from '../../contexts/UseAuth';
import currencies from '../../utilities/currencies.json';

export const ContactUs = () => {
  const auth = useAuth();

  const [userCurrency, setUserCurrency] = useState(
    auth.currency !== null ? auth.currency : 'USD'
  );

  const updateUserCurrency = (currency: string) => {
    setUserCurrency(currency);
    localStorage.setItem('ffm_currency', currency);
  };

  return (
    <FansFundMeContactUsScreen
      navbarItems={[]}
      authUser={auth.authUser}
      acceptedCurrencies={currencies}
      userCurrency={userCurrency}
      setUserCurrency={updateUserCurrency}
      cookieBannerIsVisible={auth.cookies === null}
      onClickAcceptCookies={auth.acceptCookies}
      onClickAcceptNecessaryCookies={auth.acceptNecessaryCookies}
    />
  );
};
