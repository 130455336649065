const isNotEmpty = (fieldName: string, value: string | number) => {
  if (value == '' || value == undefined) {
    return `The ${fieldName} field cannot be empty.`;
  }
};

const isEmail = (email: string) => {
  if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
    return;
  }

  return 'Please provide a valid email address.';
};

const match = (
  fieldName: string,
  first: string,
  matchFieldName: string,
  second: string
) => {
  if (first !== second) {
    return `The ${fieldName} and ${matchFieldName} values must match.`;
  }
};

const validate = (
  fieldName: string,
  value: string | number,
  setError: (value: string) => void,
  type?: 'email' | 'match' | undefined,
  matchFieldName?: string,
  matchValue?: string
) => {
  let error = '';

  if (type === 'email' && typeof value === 'string') {
    const email = isEmail(value);
    error = email != undefined ? email : error;
  }

  if (
    type === 'match' &&
    typeof value === 'string' &&
    matchFieldName !== undefined &&
    matchValue !== undefined
  ) {
    const doMatch = match(fieldName, value, matchFieldName, matchValue);
    error = doMatch != undefined ? doMatch : error;
  }

  const empty = isNotEmpty(fieldName, value);
  error = empty != undefined ? empty : error;

  setError(error);

  if (error === '') {
    return true;
  } else {
    return false;
  }
};

export default validate;
