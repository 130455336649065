import React, { useState } from 'react';
import { FansFundMeNotFoundScreen } from '@fans-fund-me/storybook';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../contexts/UseAuth';
import currencies from '../../utilities/currencies.json';

export const NotFound = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  const [userCurrency, setUserCurrency] = useState(
    auth.currency !== null ? auth.currency : 'USD'
  );

  const updateUserCurrency = (currency: string) => {
    setUserCurrency(currency);
    localStorage.setItem('ffm_currency', currency);
  };

  return (
    <FansFundMeNotFoundScreen
      navbarItems={[]}
      authUser={auth.authUser}
      acceptedCurrencies={currencies}
      userCurrency={userCurrency}
      onClick={() => {
        localStorage.setItem(
          'ffm_register_username',
          localStorage.getItem('ffm_not_found_username')!
        );
        navigate('/register/country');
      }}
      setUserCurrency={updateUserCurrency}
      cookieBannerIsVisible={auth.cookies === null}
      onClickAcceptCookies={auth.acceptCookies}
      onClickAcceptNecessaryCookies={auth.acceptNecessaryCookies}
    />
  );
};
