import { Navigate, useLocation } from 'react-router-dom';
import useAuth from '../contexts/UseAuth';

type RequireAuthProps = {
  children: React.ReactNode;
};

function RequireAuth({ children }: RequireAuthProps) {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.token) {
    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  return <>{children}</>;
}

export default RequireAuth;
