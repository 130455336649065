import React, { useEffect, useState } from 'react';
import { FansFundMeRegisterAccountScreen } from '@fans-fund-me/storybook';
import { useNavigate } from 'react-router-dom';
import validate from '../../../utilities/validation';
import axios from 'axios';
import useAuth from '../../../contexts/UseAuth';

export const RegisterAccount = () => {
  const API_ENDPOINT: string = process.env.REACT_APP_API_ENDPOINT_PROFILE ?? '';
  const AUTH_API_ENDPOINT: string =
    process.env.REACT_APP_API_ENDPOINT_AUTHENTICATION ?? '';

  const navigate = useNavigate();
  const auth = useAuth();

  const [emailAddress, setEmailAddress] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [acceptMarketing, setAcceptMarketing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setEmailError('');
  }, [emailAddress]);

  useEffect(() => {
    setPasswordError('');
  }, [password]);

  useEffect(() => {
    setConfirmPasswordError('');
  }, [confirmPassword]);

  const handleRegisterAccount = () => {
    setIsLoading(true);

    if (
      validate('email address', emailAddress, setEmailError, 'email') &&
      validate('password', password, setPasswordError) &&
      validate(
        'confirm password',
        confirmPassword,
        setConfirmPasswordError,
        'match',
        'password',
        password
      )
    ) {
      axios
        .get(`${AUTH_API_ENDPOINT}/email/${emailAddress}`)
        .then(() => {
          setIsLoading(false);

          axios
            .post(`${API_ENDPOINT}/profile`, {
              username: localStorage.getItem('ffm_register_username'),
              password: password,
              email: emailAddress,
              displayName: localStorage.getItem('ffm_register_username'),
              currency: localStorage.getItem('ffm_register_currency'),
              emailProductUpdates: acceptMarketing,
              country: localStorage.getItem('ffm_register_country'),
            })
            .then((response) => {
              const x = localStorage.getItem('ffm_currency');
              const y = localStorage.getItem('ffm_register_currency');
              const currency: string = y !== null ? y : x !== null ? x : 'USD';

              setIsLoading(false);
              auth.login(
                response.data.tokenString,
                response.data.userId,
                response.data.username,
                currency,
                'false',
                response.data.notAcceptingPaymentsReasons
              );
              navigate('/register/profile');
            })
            .catch((error) => {
              setIsLoading(false);
              setEmailError(
                error.response.data.fields?.find(
                  (field: { fieldName: string; message: string }) =>
                    field.fieldName === 'email'
                )?.message
              );
              setPasswordError(
                error.response.data.fields?.find(
                  (field: { fieldName: string; message: string }) =>
                    field.fieldName === 'password'
                )?.message
              );
              setConfirmPasswordError(
                error.response.data.fields?.find(
                  (field: { fieldName: string; message: string }) =>
                    field.fieldName === 'confirm-password'
                )?.message
              );
            });
        })
        .catch((error) => {
          setIsLoading(false);

          if (error?.response?.status === 409) {
            setEmailError(
              error.response.data.fields?.find(
                (field: { fieldName: string; message: string }) =>
                  field.fieldName === 'email'
              )?.message
            );
          } else {
            setEmailError(
              'There was an error processing your request. Please try again.'
            );
          }
        });
    } else {
      setIsLoading(false);
    }
  };

  return (
    <FansFundMeRegisterAccountScreen
      emailAddress={emailAddress}
      setEmailAddress={setEmailAddress}
      password={password}
      setPassword={setPassword}
      confirmPassword={confirmPassword}
      setConfirmPassword={setConfirmPassword}
      agreeMarketing={acceptMarketing}
      setAgreeMarketing={setAcceptMarketing}
      onClickNext={handleRegisterAccount}
      emailError={emailError}
      passwordError={passwordError}
      confirmPasswordError={confirmPasswordError}
      isLoading={isLoading}
      cookieBannerIsVisible={auth.cookies === null}
      onClickAcceptCookies={auth.acceptCookies}
      onClickAcceptNecessaryCookies={auth.acceptNecessaryCookies}
    />
  );
};
