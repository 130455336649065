import { Navigate, useLocation } from 'react-router-dom';
import useAuth from '../contexts/UseAuth';

type RequireNoAuthProps = {
  children: React.ReactNode;
};

function RequireNoAuth({ children }: RequireNoAuthProps) {
  const auth = useAuth();
  const location = useLocation();

  if (auth.token) {
    return <Navigate to='/' state={{ from: location }} replace />;
  }

  return <>{children}</>;
}

export default RequireNoAuth;
