export const PrivacyPolicy = () => {
  return (
    <div style={{ height: '100vh', minHeight: '100%' }}>
      <object
        data='/FFM-Fans-Fund-Me-Privacy-Policy-170624-185410.pdf'
        type='application/pdf'
        width='100%'
        height='100%'
      >
        <p>
          Please click here{' '}
          <a href='/FFM-Fans-Fund-Me-Privacy-Policy-170624-185410.pdf'>here</a>{' '}
          to view our Privacy Policy
        </p>
      </object>
    </div>
  );
};
