import React, { useState } from 'react';
import { FansFundMeForgotPasswordScreen } from '@fans-fund-me/storybook';
import useAuth from '../../../contexts/UseAuth';
import axios from 'axios';

export const ForgotPassword = () => {
  const auth = useAuth();

  const [emailAddress, setEmailAddress] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);

  const handleForgotPassword = () => {
    setIsLoading(true);

    const AUTH_API_ENDPOINT: string =
      process.env.REACT_APP_API_ENDPOINT_AUTHENTICATION ?? '';

    axios
      .put(`${AUTH_API_ENDPOINT}/reset-password`, {
        email: emailAddress,
      })
      .then(() => {
        setIsDone(true);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setError('There was a problem. Please try again later.');
      });
  };

  return (
    <FansFundMeForgotPasswordScreen
      emailAddress={emailAddress}
      setEmailAddress={setEmailAddress}
      onClickForgotPassword={handleForgotPassword}
      error={error}
      isLoading={isLoading}
      isDone={isDone}
      cookieBannerIsVisible={auth.cookies === null}
      onClickAcceptCookies={auth.acceptCookies}
      onClickAcceptNecessaryCookies={auth.acceptNecessaryCookies}
    />
  );
};
