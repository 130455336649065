import React, { useEffect, useState } from 'react';
import { FansFundMeVerifyEmailScreen } from '@fans-fund-me/storybook';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import useAuth from '../../contexts/UseAuth';

export const VerifyEmail = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [error, setError] = useState('');

  const { token } = useParams();

  useEffect(() => {
    axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT_PROFILE}/email-confirmation/${token}`
      )
      .then(() => {
        setIsLoading(false);
        setIsDone(false);
      })
      .catch(() => {
        setIsLoading(false);
        setIsDone(false);
        if (auth.authUser !== undefined) {
          setError(
            'There was an error confirming your email address, please request another verification email.'
          );
        } else {
          setError(
            'There was an error confirming your email address. Please try again.'
          );
        }
      });
  }, [token, auth.authUser]);

  const onClickButton = () => {
    if (error === '') {
      if (auth.authUser !== undefined) {
        navigate(`/${auth.username}`);
      } else {
        navigate('/login');
      }
    } else {
      setError('');

      if (auth.authUser !== undefined) {
        setIsButtonLoading(true);

        axios
          .post(
            `${process.env.REACT_APP_API_ENDPOINT_PROFILE}/email-confirm/${auth.userID}/`,
            {},
            { headers: { Authorization: `Bearer ${auth.token}` } }
          )
          .then(() => {
            setIsDone(true);
            setIsButtonLoading(false);
          })
          .catch(() => {
            setIsButtonLoading(false);
            setError(
              'There was an error resending your confirmation email. Try again later.'
            );
          });
      } else {
        navigate('/login');
      }
    }
  };

  return (
    <FansFundMeVerifyEmailScreen
      authUser={auth.authUser}
      isLoading={isLoading}
      isButtonLoading={isButtonLoading}
      isDone={isDone}
      error={error}
      cookieBannerIsVisible={auth.cookies === null}
      onClick={onClickButton}
      onClickAcceptCookies={auth.acceptCookies}
      onClickAcceptNecessaryCookies={auth.acceptNecessaryCookies}
    />
  );
};
