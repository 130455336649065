import React from 'react';
import { FansFundMeRegisterCountryScreen } from '@fans-fund-me/storybook';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../contexts/UseAuth';
import { regions } from 'country-data';
import currencies from '../../../utilities/currencies.json';

export const RegisterCountry = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const europe = regions.westernEurope.countries
    .concat(regions.easternEurope.countries)
    .concat(regions.southernEurope.countries)
    .concat(regions.northernEurope.countries);

  const handleRegisterCountry = (country: string, currency: string) => {
    if (!currencies.includes(currency) && europe.includes(country)) {
      currency = 'EUR';
    }

    localStorage.setItem('ffm_register_currency', currency);
    localStorage.setItem('ffm_register_country', country);
    navigate('/register/account');
  };

  return (
    <FansFundMeRegisterCountryScreen
      allowedCountries={[
        'Albania',
        'Antigua & Bermuda',
        'Argentina',
        'Armenia',
        'Australia',
        'Austria',
        'Bahamas',
        'Bahrain',
        'Belgium',
        'Benin',
        'Bolivia',
        'Bosnia & Herzegovina',
        'Botswana',
        'Bulgaria',
        'Croatia',
        'Cyprus',
        'Czech Republic',
        'Denmark',
        'Estonia',
        'Finland',
        'France',
        'Germany',
        'Gibraltar',
        'Greece',
        'Hungary',
        'Ireland',
        'Italy',
        'Latvia',
        'Liechtenstein',
        'Lithuania',
        'Luxembourg',
        'Malta',
        'Netherlands',
        'Norway',
        'Poland',
        'Portugal',
        'Romania',
        'Slovakia',
        'Slovenia',
        'Spain',
        'Sweden',
        'Switzerland',
        'United Kingdom',
        'United States',
      ]}
      onClickCountry={handleRegisterCountry}
      cookieBannerIsVisible={auth.cookies === null}
      onClickAcceptCookies={auth.acceptCookies}
      onClickAcceptNecessaryCookies={auth.acceptNecessaryCookies}
    />
  );
};
