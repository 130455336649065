const TOAST_MESSAGES = {
  PUBLIC_LEADERBOARD_TOGGLE_SUCCESS: (state: boolean) =>
    `Your account ${state ? 'is' : 'is not'} visible on the public leaderboard`,
  SURPRISE_GIFTS_TOGGLE_SUCCESS: (state: boolean) =>
    `Surprise Gifts are ${state ? 'enabled' : 'disabled'}`,
  PRODUCT_UPDATE_EMAILS_TOGGLE_SUCCESS: (state: boolean) =>
    `${
      state
        ? 'Thanks for subscribing to the FFM Newslatter!'
        : "You've unsubscribed from the FFM Newsletter"
    }`,
  GIFT_EMAILS_TOGGLE_SUCCESS: (state: boolean) =>
    `Gift email notifications have been ${state ? 'enabled' : 'disabled'}`,
  GIFT_INFO_EMAILS_TOGGLE_SUCCESS: (state: boolean) =>
    `Gift information ${state ? 'will' : 'will not'} be displayed in emails`,
  TOGGLE_ERROR: (name: string) =>
    `There was an error updating your ${name} settings`,
  IMAGE_UPLOADING: (name: string) => `Uploading ${name}`,
  IMAGE_SUCCESS: (name: string) => `${name} updated`,
  IMAGE_ERROR: (name: string) => `There was an error updating your ${name}`,
};

export default TOAST_MESSAGES;
