import { createContext } from 'react';

export interface AuthContextInterface {
  token: string | null;
  userID: string | null;
  username: string | null;
  currency: string | null;
  cookies: string | null;
  authUser:
    | {
        username: string;
        currency: string;
        isStripeSetupComplete: boolean;
        getStripeLink: () => void;
        notAcceptingPaymentsReasons: string[];
      }
    | undefined;
  acceptCookies: () => void;
  acceptNecessaryCookies: () => void;
  login: (
    t: string,
    uid: string,
    un: string,
    c: string,
    sts: string,
    nap: string[]
  ) => void;
  logout: () => void;
}

const initialContext: AuthContextInterface = {
  token: null,
  userID: null,
  username: null,
  currency: null,
  authUser: undefined,
  cookies: null,
  acceptCookies: Function,
  acceptNecessaryCookies: Function,
  login: () => {},
  logout: Function,
};

const AuthContext = createContext<AuthContextInterface>(initialContext);

export default AuthContext;
