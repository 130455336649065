export const TermsOfService = () => {
  return (
    <div style={{ height: '100vh', minHeight: '100%' }}>
      <object
        data='/FFM-Fans-Fund-Me-Terms-of-Service-170624-185334.pdf'
        type='application/pdf'
        width='100%'
        height='100%'
      >
        <p>
          Please click{' '}
          <a href='/FFM-Fans-Fund-Me-Terms-of-Service-170624-185334.pdf'>
            here
          </a>{' '}
          to view our Terms of Service
        </p>
      </object>
    </div>
  );
};
