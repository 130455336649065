import AuthContextProvider from './contexts/AuthContextProvider';
import { Navigation } from './navigation/Navigation';
import ReactGA from 'react-ga4';
import { theme } from '@fans-fund-me/storybook';
import { ThemeProvider } from 'styled-components';

export default () => {
  if (process.env.REACT_APP_GA_MEASUREMENT_ID !== undefined) {
    ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
  }

  // axios.defaults.timeout = 5000;

  return (
    <ThemeProvider theme={theme}>
      <AuthContextProvider>
        <Navigation />
      </AuthContextProvider>
    </ThemeProvider>
  );
};
