import React, { useState } from 'react';
import { FansFundMeServerErrorScreen } from '@fans-fund-me/storybook';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../contexts/UseAuth';
import currencies from '../../utilities/currencies.json';

export const ServerError = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  const [isLoading, setIsLoading] = useState(false);

  const [userCurrency, setUserCurrency] = useState(
    auth.currency !== null ? auth.currency : 'USD'
  );

  const updateUserCurrency = (currency: string) => {
    setUserCurrency(currency);
    localStorage.setItem('ffm_currency', currency);
  };

  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

  return (
    <FansFundMeServerErrorScreen
      navbarItems={[]}
      authUser={auth.authUser}
      acceptedCurrencies={currencies}
      userCurrency={userCurrency}
      onClick={async () => {
        const redirect = localStorage.getItem(
          'ffm_server_error_redirected_from'
        );
        setIsLoading(true);
        await delay(1000);
        localStorage.removeItem('ffm_server_error_redirected_from');
        navigate(redirect !== null ? redirect : '/');
      }}
      setUserCurrency={updateUserCurrency}
      isLoading={isLoading}
      cookieBannerIsVisible={auth.cookies === null}
      onClickAcceptCookies={auth.acceptCookies}
      onClickAcceptNecessaryCookies={auth.acceptNecessaryCookies}
    />
  );
};
