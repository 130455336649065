import { RouterProvider } from 'react-router-dom';
import router from '../navigation/router';
import { AxiosInterceptors } from '../api/axios';
import useAuth from '../contexts/UseAuth';
import useRunOnce from '../utilities/UseRunOnce';

export const Navigation = () => {
  const auth = useAuth();

  useRunOnce({
    fn: () => {
      AxiosInterceptors(auth);
    },
  });

  return <RouterProvider router={router} />;
};
